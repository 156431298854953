/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dayjs from 'dayjs';
import { Button, Card, CardActions, CardContent, IconButton, Paper, Typography } from '@mui/material';
import List from '@mui/material/List';
import MapIcon from '@mui/icons-material/Map';
import EditIcon from '@mui/icons-material/Edit';

import { ViewHeader } from '@components/ViewHeader';
import { EmptyState } from '@components/Planner/EmptyState';
import { useAppTheme } from '@hooks/useAppTheme';
import { useDeletePlanConfirmationModal } from '@hooks/modals/useDeletePlanConfirmationModal';
import { STEP_STATUS } from '@mytypes/protocol';
import { getAllFieldVisitPlansWithDexie, getFieldByProtocolWithDexie } from '@services/local';
import { styles } from './styles';

const Planner = () => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const navigate = useNavigate();
  const { displayModal: displayDeletePlanConfirmationModal, planIdDeleted } = useDeletePlanConfirmationModal();
  const [emptyState, setEmptyState] = useState<boolean>(false);
  const [fieldVisitsPlans, setFieldVisitsPlans] = useState<any[]>([]);

  const getPlans = async () => {
    const plans = await getAllFieldVisitPlansWithDexie();
    if (plans.length === 0) {
      setEmptyState(true);
      return;
    }
    let processedPlans = [];
    for (const plan of plans) {
      let fieldVisits = 0;
      let fieldVisitsCompleted = 0;
      const fields = plan.fields;
      if (fields) {
        for (const field of fields) {
          const fieldObject = await getFieldByProtocolWithDexie(field.fieldId, field.protocolId);
          if (fieldObject && fieldObject.visits) {
            for (const visit of fieldObject.visits) {
              fieldVisits++;
              if (visit.mainActionCompleted === STEP_STATUS.COMPLETE) fieldVisitsCompleted++;
            }
          }
        }
      }
      processedPlans.push({ ...plan, fieldVisits, fieldVisitsCompleted, createdAt: plan.createdAt, updatedAt: plan.updatedAt });
    }
    setFieldVisitsPlans(processedPlans);
  };

  useEffect(() => {
    getPlans();
  }, []);

  const handleOnDeletePlan = async (id: string) => {
    displayDeletePlanConfirmationModal(Number(id));
  };

  useEffect(() => {
    if (planIdDeleted) {
      setFieldVisitsPlans(fieldVisitsPlans.filter(fieldVisitsPlan => fieldVisitsPlan.id !== planIdDeleted));
    }
  }, [planIdDeleted]);

  return (
    <Paper css={styles.container(theme)}>
      <ViewHeader title={t('planner.title')} hideGoBackBtn={true} />

      <div css={styles.scrollableArea(theme)}>
        {emptyState ? (
          <EmptyState />
        ) : (
          <List>
            {fieldVisitsPlans.map(({ createdAt, fieldVisits, fieldVisitsCompleted, id, name, updatedAt }, index) => (
              <Card key={index} elevation={0} css={styles.planCard(theme)}>
                <div css={styles.planCardHeader}>
                  <Typography color="text.primary" css={styles.planCardHeaderText(theme)}>
                    {name}
                  </Typography>

                  <IconButton size="small" onClick={() => navigate(`/planner/new/${id}`)}>
                    <EditIcon />
                  </IconButton>
                </div>
                <CardContent css={styles.cardContent(theme)}>
                  <Typography color="text.primary" fontSize={14}>
                    {t('general.date')}: <span css={styles.cardContentText}> {Dayjs(createdAt).format('MM/DD/YYYY')}</span>
                  </Typography>
                  <Typography color="text.primary" fontSize={14}>
                    {t('planner.field-visit-completed-label')}:{' '}
                    <span css={styles.cardContentText}>
                      {fieldVisitsCompleted} of {fieldVisits}
                    </span>
                  </Typography>
                  <Typography color="text.primary" fontSize={14}>
                    {t('planner.last-update-label')}: <span css={styles.cardContentText}> {Dayjs(updatedAt).format('MM/DD/YYYY hh:mm A')}</span>
                  </Typography>
                </CardContent>

                <CardActions css={styles.cardActions}>
                  <div>
                    <Button variant="text" size="small" color="error" onClick={() => handleOnDeletePlan(id)}>
                      {t('general.delete')}
                    </Button>
                  </div>
                  <Button variant="text" size="small" color="success" onClick={() => navigate(`/planner/${id}/download`)}>
                    {t('planner.update-now-button')}
                  </Button>
                  <Button variant="contained" size="small" onClick={() => navigate(`/planner/${id}`)}>
                    {t('general.view')}
                  </Button>
                </CardActions>
              </Card>
            ))}
          </List>
        )}
      </div>

      {!emptyState && (
        <div css={styles.buttonsContainer(theme)}>
          <Button variant="outlined" startIcon={<MapIcon />} onClick={() => navigate('/planner/new')}>
            {t('planner.create-new-plan-button')}
          </Button>
        </div>
      )}
    </Paper>
  );
};

export default Planner;
