/** @jsxImportSource @emotion/react */
import * as turf from '@turf/turf';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useErrorBoundary } from 'react-error-boundary';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Alert, AlertTitle, Button, ButtonGroup, Paper, Snackbar, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';
import SignalWifi1BarIcon from '@mui/icons-material/SignalWifi1Bar';

import { AutoSaveBtn } from '@components/AutoSaveBtn';
import { ViewHeader } from '@components/ViewHeader';
import MapView from '@components/MapView/MapView';
import CharacteristicSections from '@components/FieldVisitWizard/CharacteristicSections/CharacteristicSections';
import { GpsSimulationControls } from '@components/FieldVisitWizard/GpsSimulationControls';
import { Characteristic } from '@components/FieldVisitWizard/Characteristic';
import { MAP_LAYERS } from '@components/FieldMapOptionsModal/FieldMapOptionsModal';
import { Alerts } from '@components/FieldVisitWizard/FieldVisitAlertsModal';
import { DownloadFieldDataBanner } from '@components/DownloadFieldDataBanner';
import { LocationWarningType } from '@components/LocationWarningModal/LocationWarningModal';
import { useAppTheme } from '@hooks/useAppTheme';
import { SITE_MODE, useOnSiteModal } from '@hooks/modals/useOnSiteModal';
import { useFieldMapOptionsModal } from '@hooks/modals/useFieldMapOptionsModal';
import { useGetFieldTables } from '@hooks/useGetFieldTables';
import { useGetFieldVisitsSteps } from '@hooks/useGetFieldVisitsSteps';
import { useSubmitsFieldDataAsync } from '@hooks/useSubmitsFieldDataAsync';
import { useSubmitsFieldDataSync } from '@hooks/useSubmitsFieldDataSync';
import { useLocationWarningModal } from '@hooks/modals/useLocationWarningModal';
import { useMarkerDetailsModal } from '@hooks/modals/useMarkerDetailsModal';
import { useFieldVisitAlertsModal } from '@hooks/modals/useFieldVisitAlertsModal';
import { useLostChangesVisitModal } from '@hooks/modals/useLostChangesVisitModal';
import { useUnableAccessDeviceLocationModal } from '@hooks/modals/useUnableAccessDeviceLocationModal';
import { FieldVisitProgressObjStore, LocalFieldVisit, LocalVisitProgress } from '@mytypes/local';
import { Position } from '@mytypes/map';
import { FieldVisitStep, Page, QUESTION_TYPE, STEP_STATUS } from '@mytypes/protocol';
import { alertQuestion } from '@mytypes/fieldVisitWizard';
import {
  clearFieldVisitProgressWithDexie,
  getFieldVisitProgressWithDexie,
  getFieldVisitForWizardWithDexie,
  setFieldVisitProgressWithDexie,
  createFieldVisitWithDexie,
  updateFieldVisitWithDexie,
  getFieldWithDexie
} from '@services/local';
import {
  setPages,
  setFieldBoundary as setFieldBoundaryState,
  setActiveZones,
  setSelectedMarker,
  setPreviousVisitsSelection,
  setChangedCharacteristic
} from '@state-mgmt/slices/visitSlice';
import { RootState } from '@state-mgmt/store';
import { setShowAppNavigator, setWatchId, setCurrentPosition, setAccuracy } from '@state-mgmt/slices/appSlice';
import { generateFakeCoords } from '@utils/generateFakeCoords';
import { getFieldCenterPoint } from '@utils/getFieldCenterPoint';
import { groupAlertsQuestionsByPage } from '@utils/groupAlertsQuestionsByPage';
import { wait } from '@utils/wait';
import config from '../../../config';
import { styles } from './styles';

const colors = ['#650465', '#040665', '#044833', '#1b5e01', '#6d6209', '#6d3909', '#3d0465', '#6d0909', '#c13333', '#ffa10a'];

const FieldVisitWizard = () => {
  const { t } = useTranslation();
  const { downloadId, fieldId, protocolId, stepId } = useParams();
  const [searchParams] = useSearchParams();
  const theme = useAppTheme({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const characteristicSectionRef = useRef<HTMLDivElement>();
  const { showBoundary } = useErrorBoundary();

  const { accuracy, clientId, currentPosition, online, offSite, simulateGPS, trialId, watchId } = useSelector((state: RootState) => state.app);
  const { clients } = useSelector((state: RootState) => state.client);
  const { fields, trialGrowers, trialProducts, trials } = useSelector((state: RootState) => state.trial);
  const { protocols } = useSelector((state: RootState) => state.protocol);
  const { activeZones, changedCharacteristic, pages, previousVisitsSelection } = useSelector((state: RootState) => state.visit);

  const { displayModal: displayFieldMapOptionsModal, selectedLayers } = useFieldMapOptionsModal();
  const { displayModal: displayFieldVisitAlertsModal, readyToSubmit } = useFieldVisitAlertsModal();
  const { displayModal: displayLocationWarningModal } = useLocationWarningModal();
  const { displayModal: displayMarkerDetailsModal } = useMarkerDetailsModal();
  const { displayModal: displayLostChangesVisitModal } = useLostChangesVisitModal();
  const { displayModal: displayUnableAccessDeviceLocationModal } = useUnableAccessDeviceLocationModal();
  const { displayModal: displayOffSiteModal, siteMode } = useOnSiteModal();

  const [checked, setChecked] = useState<string[]>([]);
  const [downloadLastUpdate, setDownloadLastUpdate] = useState<any>();
  const [fakeCoords, setFakeCoords] = useState<any[] | undefined>();
  const [fieldBoundary, setFieldBoundary] = useState<any | undefined>();
  const [trialAreaGeoJson, setTrialAreaGeoJson] = useState<any | undefined>();
  const [getFieldTablesState, getFieldTables] = useGetFieldTables();
  const [getFieldVisitsStepsState, getFieldVisitsSteps] = useGetFieldVisitsSteps();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(true);
  const [localId, setLocalId] = useState<number>();
  const [markerFocus, setMarkerFocus] = useState<string | undefined>();
  const [markers, setMarkers] = useState<any[]>([]);
  const [savedValues, setSavedValues] = useState<any[]>([]);
  const [showEditMarkerLocationMessage, setShowEditMarkerLocationMessage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [simulationIndex, setSimulationIndex] = useState<number>(0);
  const [soilGeojson, setSoilGeojson] = useState<any | undefined>();
  const [step, setStep] = useState<number>(0);
  const submitsFieldDataSync = useSubmitsFieldDataSync();
  const [submitsFieldDataState, submitsFieldData] = useSubmitsFieldDataAsync();
  const [treatmentZoneLegends, setTreatmentZoneLegends] = useState<any[]>([]);
  const [zoneGeojson, setZoneGeojson] = useState<any | undefined>();
  const [previousVisits, setPreviousVisits] = useState<any[]>([]);
  const [previousVisitsMarkers, setPreviousVisitsMarkers] = useState<any[]>([]);
  const [stepName, setStepName] = useState<string>();
  const [isOffSiteByPrompt, setIsOffSiteByPrompt] = useState<boolean>(false);
  const [wizardIsMounted, setWizardIsMounted] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>();
  const [progress, setProgress] = useState<FieldVisitProgressObjStore>();
  const [fieldClientId, setFieldClientId] = useState<string>();
  const [fieldTrialId, setFieldTrialId] = useState<string>();
  const [fieldProtocolId, setFieldProtocolId] = useState<string>();

  // const [intervalGPS, setIntervalGPS] = useState<any>();

  const client = useMemo(() => clients.find(client => client.id === clientId), [clients, clientId]);

  const trial = useMemo(() => trials.find(trial => trial.id === trialId), [trials, trialId]);

  const protocol = useMemo(() => protocols.find(protocol => protocol.id === protocolId), [protocols, protocolId]);

  const field = useMemo(() => fields.find(field => field.field_id === fieldId && field.protocol_id === protocolId), [fields, fieldId, protocolId]);

  const grower = useMemo(() => trialGrowers.find(grower => grower.user_id === field?.owner_id), [field, trialGrowers]);

  const accuracyIcon = useMemo(() => {
    if (accuracy) {
      if (accuracy <= config.REACT_APP_ACCURACY_GOOD) return <SignalWifi4BarIcon color="success" sx={{ fontSize: 18 }} />;
      if (accuracy <= config.REACT_APP_ACCURACY_FAIR) return <SignalWifi3BarIcon color="warning" sx={{ fontSize: 18 }} />;
      return <SignalWifi1BarIcon color="error" sx={{ fontSize: 18 }} />;
    }
  }, [accuracy]);

  const skipLocationChecks = useMemo(() => isOffSiteByPrompt || offSite || isEditMode, [isOffSiteByPrompt, offSite, isEditMode]);

  useEffect(() => {
    dispatch(setPages([]));
  }, []);

  useEffect(() => {
    if (!offSite && !isEditMode) {
      displayOffSiteModal();
    }
  }, [offSite, isEditMode]);

  useEffect(() => {
    if (siteMode === SITE_MODE.OFF) {
      setIsOffSiteByPrompt(true);
    }
  }, [siteMode]);

  useEffect(() => {
    dispatch(setShowAppNavigator(false));
    if (!fieldId || !protocolId || !stepId) return;

    if (downloadId) {
      // fetch from planner
      getFieldWithDexie(Number(downloadId)).then(item => {
        if (item) {
          const localvisits = item.visits;
          if (simulateGPS) {
            setFakeCoords(generateFakeCoords(item.boundaries.field.features.at(0).geometry, 50));
          }
          setFieldClientId(item.clientId);
          setFieldTrialId(item.trialId);
          setFieldProtocolId(item.protocolId);
          setDownloadLastUpdate(item.createdAt);
          // hack to wait to the map load
          wait(0).then(() => {
            setFieldBoundary(item.boundaries.field);
            setTrialAreaGeoJson(item.boundaries.area);
            setZoneGeojson(item.boundaries.treatmentsZones);
            setSoilGeojson(item.boundaries.soil);
          });
          getFieldVisitForWizardWithDexie(fieldId, protocolId, stepId).then(visit => {
            if (visit) {
              // use from local
              setIsEditMode(true);
              setLocalId(visit.id);
              initSections(visit);
            } else {
              initSections(step);
            }
          });

          const step = localvisits.filter((localvisit: any) => localvisit.stepId === stepId).at(0);
          if (step) {
            initSections(step);
          }
        }
      });
    } else {
      if (trialId) {
        getFieldTables(trialId, protocolId, fieldId, true, true);
        getFieldVisitForWizardWithDexie(fieldId, protocolId, stepId).then(visit => {
          if (visit) {
            // use from local
            setIsEditMode(true);
            setLocalId(visit.id);
            initSections(visit);
          } else {
            // fetch from server
            getFieldVisitsSteps(trialId, protocolId, fieldId, [stepId]);
          }
        });
      }
    }
  }, [fieldId]);

  useEffect(() => {
    if (getFieldVisitsStepsState.data) {
      const previousVisit = getFieldVisitsStepsState.data?.filter((step: any) => step.stepId !== stepId && step.mainActionCompleted === STEP_STATUS.COMPLETE);
      setPreviousVisits(previousVisit);
      dispatch(
        setPreviousVisitsSelection(
          previousVisit.map((visit, index) => ({ id: visit.stepId, label: visit.label, completedAt: visit.completedAt, selected: false, color: colors[index] }))
        )
      );

      const step = getFieldVisitsStepsState.data?.find((step: any) => step.stepId === stepId);
      if (step) {
        setIsEditMode(step.mainActionCompleted === STEP_STATUS.COMPLETE);
        initSections(step);
      }
    }
  }, [getFieldVisitsStepsState.data]);

  useEffect(() => {
    if (fakeCoords) {
      dispatch(
        setCurrentPosition({
          latitude: fakeCoords[simulationIndex].latitude,
          longitude: fakeCoords[simulationIndex].longitude
        })
      );
    }
  }, [simulationIndex]);

  // useEffect(() => {
  //   if (fakeCoords && !isDragging) {
  //     setIntervalGPS(
  //       setInterval(() => {
  //         setSimulationIndex(prevstate => (prevstate < fakeCoords.length - 1 ? prevstate + 1 : 0));
  //       }, 5000)
  //     );
  //   }
  // }, [fakeCoords, isDragging]);

  useEffect(() => {
    if (isDragging) {
      stopGettingPosition();
      // clearInterval(intervalGPS);
    } else {
      startGettingPosition();
    }
  }, [isDragging]);

  useEffect(() => {
    if (getFieldTablesState.data) {
      if (getFieldTablesState.data.field) {
        const data = getFieldTablesState.data.field;
        setFieldBoundary(data);

        if (!currentPosition && !simulateGPS) {
          displayUnableAccessDeviceLocationModal('init');
        }
        if (simulateGPS) {
          setFakeCoords(generateFakeCoords(data.features.at(0).geometry, 50));
        }
      }

      if (getFieldTablesState.data.area) {
        setTrialAreaGeoJson(getFieldTablesState.data.area);
      }

      if (getFieldTablesState.data.treatmentsZones) {
        let treatmentZones: any[] = [];
        getFieldTablesState.data.treatmentsZones.features.forEach((treatmentZone: any) => {
          const zoneProductId = treatmentZone.properties.product_id;
          if (zoneProductId) {
            const product = trialProducts.find(trialProduct => trialProduct.id === zoneProductId);
            if (product) {
              treatmentZones = [
                ...treatmentZones,
                { zone_id: treatmentZone.properties.zone_id, label: product.name, geometry: treatmentZone.geometry, color: product.color, active: true }
              ];
            }
          }
        });
        setTreatmentZoneLegends(treatmentZones);
        dispatch(setActiveZones(treatmentZones));
        setZoneGeojson(getFieldTablesState.data.treatmentsZones);
      }

      if (getFieldTablesState.data.soil) {
        const data = getFieldTablesState.data.soil;
        setSoilGeojson(data);
      }
    }
  }, [getFieldTablesState.data]);

  useEffect(() => {
    if (submitsFieldDataState.isSuccess) {
      clearFieldVisitProgressWithDexie().then(() => closeWizard());
    }
  }, [submitsFieldDataState.isSuccess]);

  useEffect(() => {
    if (submitsFieldDataState.error) {
      setShowError(true);
    }
  }, [submitsFieldDataState.error]);

  useEffect(() => setShowEditMarkerLocationMessage(markers.some(marker => marker.draggable)), [markers]);

  useEffect(() => {
    setMarkers(markers.map(marker => (marker.id === markerFocus ? { ...marker, focus: true, draggable: true } : { ...marker, focus: false })));
  }, [markerFocus]);

  useEffect(() => {
    readyToSubmit && saveVisit();
  }, [readyToSubmit]);

  useEffect(() => {
    if (fieldBoundary) dispatch(setFieldBoundaryState(fieldBoundary));
  }, [fieldBoundary]);

  useEffect(() => {
    updateMarkers(pages);
  }, [activeZones, checked, pages]);

  useEffect(() => {
    let visits: any = [];
    previousVisits.forEach(previousVisit => {
      const isSelected = previousVisitsSelection.find(pvs => pvs.id === previousVisit.stepId && pvs.selected);
      if (isSelected) {
        visits = [...visits, { ...previousVisit, color: isSelected.color }];
      }
    });

    if (!visits.length) {
      setPreviousVisitsMarkers([]);
    }
    let previousMarker: any = [];
    visits.forEach((visit: any) => {
      const reduced = visit.pages.reduce((acc: any, page: any) => {
        let validQuestions: any = [];
        page.questions.forEach((question: any) => {
          if (question.valueType === QUESTION_TYPE.UPLOAD) {
            const uploadsValues = question.value || [];
            const uploads = uploadsValues.map((upload: any) => ({ ...question, value: upload.url, latitude: upload.latitude, longitude: upload.longitude }));
            validQuestions = [...validQuestions, ...uploads];
          } else {
            if (question.value !== undefined && question.latitude !== undefined && question.longitude !== undefined) {
              validQuestions = [...validQuestions, question];
            }
          }
        });

        validQuestions.forEach((question: any) => {
          const result = {
            characterization_id: question.characterization_id,
            color: visit.color,
            label: question.label,
            latitude: question.latitude,
            longitude: question.longitude,
            value: question.value,
            valueType: question.valueType
          };

          acc.push(result);
        });

        return acc;
      }, []);
      previousMarker = [...previousMarker, ...reduced];
    });
    setPreviousVisitsMarkers(previousMarker);
  }, [previousVisitsSelection]);

  useEffect(() => {
    if (changedCharacteristic) {
      setIsSaving(true);
      wait(1000).then(() => setIsSaving(false));
      if (changedCharacteristic && trial && protocol && field && grower && stepId && stepName) {
        const visit: LocalVisitProgress = {
          lastChangedCharacteristic: changedCharacteristic,
          fieldId: field.field_id,
          fieldName: field.name,
          growerName: `${grower.first_name} ${grower.last_name}`,
          pages,
          protocolId: protocol.id,
          protocolName: protocol.name,
          savedValues,
          stepId,
          stepName,
          trialId: trial.id,
          trialName: trial.name,
          url: location.pathname + location.search
        };

        setFieldVisitProgressWithDexie(visit);
        dispatch(setChangedCharacteristic(undefined));
      }
    }
  }, [changedCharacteristic]);

  useEffect(() => {
    if (wizardIsMounted) {
      getFieldVisitProgressWithDexie().then(progress => {
        if (progress) {
          setProgress(progress);
          handleOnMarkerLocationClicked(progress?.lastChangedCharacteristic);
        }
      });
    }
  }, [wizardIsMounted]);

  useEffect(() => {
    if (fieldBoundary && pages.length && checked.length) {
      setWizardIsMounted(true);
    }
  }, [fieldBoundary, pages, checked]);

  const initSections = async (step: FieldVisitStep | LocalFieldVisit) => {
    try {
      setStepName(step?.label || '');
      const visitProgress = await getFieldVisitProgressWithDexie();
      if (visitProgress) {
        setChecked(visitProgress.pages.map(page => page.pageId));
        dispatch(setPages(visitProgress.pages));
        return;
      }

      const pagesOrdered = [...step.pages];
      pagesOrdered.sort((a: Page, b: Page) => a.order.toString().localeCompare(b.order.toString(), undefined, { numeric: true }));
      dispatch(setPages(pagesOrdered));
      if (step.savedValues) setSavedValues(step.savedValues);
    } catch (error) {
      showBoundary(error);
    }
  };

  const checkLocations = (question: any) => {
    try {
      if (question.valueType === QUESTION_TYPE.UPLOAD) {
        question.value = question.value.map((value: any) => (value.remove ? { ...value, latitude: undefined, longitude: undefined } : value));
        return question;
      } else {
        return question.value ? question : { ...question, latitude: undefined, longitude: undefined };
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  const hasLocation = (characterizationId: string) => {
    try {
      const value = savedValues.find(savedValue => savedValue.characterization_id === characterizationId);
      return value?.location;
    } catch (error) {
      showBoundary(error);
    }
  };

  const assignValueToQuestion = (pageId: string, characterizationId: string, value: any) => {
    try {
      if (!fieldBoundary) return;

      dispatch(setChangedCharacteristic(characterizationId));
      const position = currentPosition;

      const newPages = pages.map(page => {
        let questions = page.questions;
        if (page.pageId === pageId) {
          questions = questions.map(question => {
            if (question.characterization_id === characterizationId) {
              const newQuestionObj = {
                ...question,
                value,
                ...(value && !hasLocation(characterizationId) && !skipLocationChecks && position)
              };
              return checkLocations(newQuestionObj);
            }
            return question;
          });
        }
        return { ...page, questions };
      });
      dispatch(setPages(newPages));

      // update markers
      let newMarkers: any = [];
      newPages.forEach(({ questions }) => {
        questions.forEach(answeredQuestion => {
          if (answeredQuestion.value && answeredQuestion.valueType === QUESTION_TYPE.UPLOAD) {
            answeredQuestion.value.forEach((value: any) => {
              if (value.latitude && value.longitude) {
                newMarkers.push({
                  id: answeredQuestion.characterization_id,
                  draggable: false,
                  focus: answeredQuestion.characterization_id === markerFocus ? true : false,
                  thumb: value.image || value.url,
                  latitude: value.latitude,
                  longitude: value.longitude
                });
              }
            });
          } else {
            if (answeredQuestion.latitude && answeredQuestion.longitude) {
              newMarkers.push({
                id: answeredQuestion.characterization_id,
                draggable: false,
                focus: answeredQuestion.characterization_id === markerFocus ? true : false,
                latitude: answeredQuestion.latitude,
                longitude: answeredQuestion.longitude
              });
            }
          }
        });
      });
      setMarkers(newMarkers);

      if (!skipLocationChecks && value && value.length && position) {
        // @todo convert this to new util function
        const isInside = turf.booleanPointInPolygon(
          turf.point([position.longitude, position.latitude]),
          turf.polygon(fieldBoundary.features[0].geometry.coordinates)
        );
        if (!isInside) {
          displayLocationWarningModal(characterizationId, position, LocationWarningType.OUT_OF_BOUNDARIES);
          return;
        }
      }

      if (!skipLocationChecks && value && value.length && position && accuracy && accuracy > config.REACT_APP_ACCURACY_GOOD) {
        displayLocationWarningModal(characterizationId, position, LocationWarningType.ACCURACY);
        return;
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  const preComplete = () => {
    try {
      if (!currentPosition && !simulateGPS) {
        displayUnableAccessDeviceLocationModal('submit', () => onCompleteBtnPress());
      } else {
        onCompleteBtnPress();
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  const onCompleteBtnPress = () => {
    try {
      const alerts: Alerts = {
        optionals: 0,
        optionalsList: [],
        required: 0,
        requiredList: []
      };
      const requiredQuestions: alertQuestion[] = [];
      const optionalQuestions: alertQuestion[] = [];

      pages.forEach(page => {
        if (checked.includes(page.pageId)) {
          page.questions.forEach(question => {
            if (typeof question.value === 'undefined') {
              if (question.required) {
                requiredQuestions.push({ sectionId: page.pageId, sectionLabel: page.label, question: question.label });
                alerts.required = alerts.required + 1;
              } else {
                optionalQuestions.push({ sectionId: page.pageId, sectionLabel: page.label, question: question.label });
                alerts.optionals = alerts.optionals + 1;
              }
            }
          });
        }
      });

      alerts.requiredList = groupAlertsQuestionsByPage(requiredQuestions);
      alerts.optionalsList = groupAlertsQuestionsByPage(optionalQuestions);

      if (alerts.required > 0 || alerts.optionals > 0) {
        displayFieldVisitAlertsModal(alerts);
        return;
      }
      saveVisit();
    } catch (error) {
      showBoundary(error);
    }
  };

  const hasAnsweredAtleastOneQuestion = useMemo(
    () =>
      pages.some(page => {
        return page.questions.some(question => {
          return typeof question.value !== 'undefined' && (!Array.isArray(question.value) || question.value.length);
        });
      }),
    [pages]
  );

  const closeWizard = () => {
    try {
      stopGettingPosition();
      setShowError(false);
      setIsSaving(false);
      dispatch(setPages([]));
      dispatch(setActiveZones([]));
      dispatch(setFieldBoundaryState(undefined));
      dispatch(setSelectedMarker(undefined));
      dispatch(setShowAppNavigator(true));
      dispatch(setChangedCharacteristic(undefined));

      let url = `/field-visits/${fieldId}/${protocolId}`;
      if (downloadId) {
        url += `/${downloadId}`;
      }

      url += `?grower_id=${searchParams.get('grower_id')}`;

      if (searchParams.get('plan_id')) {
        url += `&plan_id=${searchParams.get('plan_id')}`;
      }
      if (searchParams.get('operative')) {
        url += `&operative=${searchParams.get('operative')}`;
      }

      navigate(url);
    } catch (error) {
      showBoundary(error);
    }
  };

  const switchFromDownloadToOnline = () => {
    try {
      stopGettingPosition();
      setShowError(false);
      dispatch(setPages([]));
      dispatch(setShowAppNavigator(true));
      const redirectTo = encodeURIComponent(`/field-visits/${fieldId}/${protocolId}/start/${stepId}?grower_id=${searchParams.get('grower_id')}`);
      navigate(`/splash?redirectTo=${redirectTo}`);
    } catch (error) {
      showBoundary(error);
    }
  };

  const saveVisit = async (complete: boolean = true) => {
    try {
      let fieldVisitLocalId = localId;
      if (!localId && (fieldTrialId || trialId) && (fieldProtocolId || protocolId) && stepId && fieldId) {
        // Bug fix: use fieldClientId, fieldTrialId, and fieldProtocolId as source of truth (for offline usage)
        const id = await createFieldVisitWithDexie({
          clientId: fieldClientId ? fieldClientId : clientId || '',
          clientName: client?.name || '',
          trialId: fieldTrialId ? fieldTrialId : trialId ? trialId : progress?.trialId || '',
          trialName: trial ? trial?.name : progress?.trialName || '',
          protocolId: fieldProtocolId ? fieldProtocolId : protocolId || '',
          protocolName: protocol?.name || '',
          fieldId,
          fieldName: field?.name || '',
          stepId,
          pages,
          savedValues,
          complete
        });
        fieldVisitLocalId = id;
        setLocalId(id);
      }

      // update the local field visit on editing mode
      if (localId) {
        await updateFieldVisitWithDexie(localId, {
          pages,
          savedValues,
          stepId,
          complete
        });
      }

      if (online && protocolId && fieldVisitLocalId) {
        if (complete) {
          submitsFieldData(fieldVisitLocalId, true);
        } else {
          // used to "save as we go". This is to save progress in between sections. Not as strict as the one above.
          submitsFieldDataSync(fieldVisitLocalId);
        }
        return;
      } else if (complete) {
        await clearFieldVisitProgressWithDexie();
        closeWizard();
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  const isMarkerInNoActiveZone = (zones: any[], position: Position) => {
    try {
      for (const zone of zones) {
        const isInside = turf.booleanPointInPolygon(turf.point([position.longitude, position.latitude]), turf.polygon(zone.geometry.coordinates));
        if (isInside && !zone.active) {
          return true;
        }
      }
      return false;
    } catch (error) {
      showBoundary(error);
    }
  };

  const updateMarkers = (pages: Page[]) => {
    try {
      let newMarkers: any = [];
      pages.forEach(({ pageId, questions }) => {
        if (!checked.includes(pageId)) {
          return;
        }

        questions.forEach(question => {
          const zones = activeZones;
          if (
            question.latitude &&
            question.longitude &&
            zones.length &&
            isMarkerInNoActiveZone(zones, { latitude: question.latitude, longitude: question.longitude })
          ) {
            return;
          }

          if (question.value && Array.isArray(question.value) && question.valueType === QUESTION_TYPE.UPLOAD) {
            question.value.forEach((value: any) => {
              if (value.latitude && value.longitude) {
                newMarkers.push({
                  id: question.characterization_id,
                  draggable: false,
                  focus: question.characterization_id === markerFocus ? true : false,
                  thumb: value.image || value.url,
                  latitude: value.latitude,
                  longitude: value.longitude
                });
              }
            });
          } else {
            if (question.latitude && question.longitude) {
              newMarkers.push({
                id: question.characterization_id,
                draggable: question.characterization_id === markerFocus ? true : false,
                focus: question.characterization_id === markerFocus ? true : false,
                latitude: question.latitude,
                longitude: question.longitude
              });
            }
          }
        });
      });
      setMarkers(newMarkers);
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleOnFocusMarkerLocationChange = (characterizationId: string, newPosition: Position) => {
    try {
      dispatch(setChangedCharacteristic(characterizationId));

      const newPages = pages.map(page => {
        let questions = page.questions;
        questions = questions.map(question =>
          question.characterization_id === characterizationId ? { ...question, longitude: newPosition.longitude, latitude: newPosition.latitude } : question
        );

        return { ...page, questions };
      });

      dispatch(setPages(newPages));
      updateMarkers(newPages);
      setMarkerFocus(characterizationId);
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleOnMarkerLocationClicked = (id: string) => {
    try {
      let currentIndex = 0;
      let targetIndexPage = 0;
      for (const page of pages) {
        if (checked.includes(page.pageId)) {
          currentIndex++;
        }
        const characteristic = page.questions.find(({ characterization_id }) => characterization_id === id);
        if (characteristic) {
          targetIndexPage = currentIndex;
        }
      }
      if (currentIndex) {
        setStep(targetIndexPage);
        dispatch(setSelectedMarker(id));
        setMarkerFocus(id);
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleOnPreviousMarkerLocationClicked = (id: string) => {
    try {
      const markerInfo = previousVisitsMarkers.find(pvm => pvm.characterization_id === id);
      displayMarkerDetailsModal(markerInfo);
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleOnQuestionExpanded = (characterizationId: string | undefined) => {
    try {
      setMarkerFocus(characterizationId);
      setMarkers(markers.map(marker => ({ ...marker, draggable: false })));
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleOnReCenterLocationEnabled = (characterizationId: string) => {
    try {
      dispatch(setChangedCharacteristic(characterizationId));
      const centerPoint = getFieldCenterPoint(fieldBoundary);

      const newPages = pages.map(page => {
        let questions = page.questions;
        questions = questions.map(question => (question.characterization_id === characterizationId ? { ...question, ...centerPoint } : question));

        return { ...page, questions };
      });
      dispatch(setPages(newPages));
      updateMarkers(newPages);
    } catch (error) {
      showBoundary(error);
    }
  };

  const handleOnAddLocation = (characterizationId: string) => {
    try {
      dispatch(setChangedCharacteristic(characterizationId));
      const centerPoint = getFieldCenterPoint(fieldBoundary);
      const newPages = pages.map(page => {
        let questions = page.questions;
        questions = questions.map(question => (question.characterization_id === characterizationId ? { ...question, ...centerPoint } : question));

        return { ...page, questions };
      });
      dispatch(setPages(newPages));
      updateMarkers(newPages);
    } catch (error) {
      showBoundary(error);
    }
  };

  const startGettingPosition = () => {
    try {
      const newWatchId = navigator.geolocation.watchPosition(
        ({ coords }) => {
          dispatch(setAccuracy(coords.accuracy));
          dispatch(
            setCurrentPosition({
              latitude: coords.latitude,
              longitude: coords.longitude
            })
          );
        },
        async err => console.error(`Error watching the position: ${err.message}`),
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
      dispatch(setWatchId(newWatchId));
    } catch (error) {
      showBoundary(error);
    }
  };

  const stopGettingPosition = () => {
    try {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
        dispatch(setWatchId(undefined));
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  const onCancelWizard = async () => {
    try {
      const visitProgress = await getFieldVisitProgressWithDexie();
      if (visitProgress) {
        displayLostChangesVisitModal(() => closeWizard());
      } else {
        closeWizard();
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <>
      <LoadingOverlay
        spinner
        active={submitsFieldDataState.isLoading}
        text={t('field-visit-wizard.saving-message')}
        styles={{
          wrapper: {
            height: '100vh'
          }
        }}
      >
        <Paper css={styles.container(theme)}>
          <ViewHeader title={t('field-visit-wizard.title')} onGoBackBtnPress={onCancelWizard} rightBtn={<AutoSaveBtn isSaving={isSaving} />} />
          {downloadId && <DownloadFieldDataBanner downloadLastUpdate={downloadLastUpdate} onSwitchBtnPress={() => switchFromDownloadToOnline()} />}

          {simulateGPS && fakeCoords && (
            <GpsSimulationControls
              disablePreviusBtn={simulationIndex === 0}
              disableNextBtn={simulationIndex === fakeCoords.length - 1}
              onPreviusBtnClicked={() => setSimulationIndex(simulationIndex - 1)}
              onNextBtnClicked={() => setSimulationIndex(simulationIndex + 1)}
            />
          )}

          <div css={styles.mapContainer}>
            <LoadingOverlay
              spinner
              active={getFieldTablesState.isLoading || getFieldVisitsStepsState.isLoading}
              styles={{ wrapper: styles.mapLoadingOverlayWrapper }}
              text={t('field-visit-wizard.loading-map-message')}
            >
              <MapView
                fieldBoundary={fieldBoundary}
                markers={markers}
                onDraggStatusChange={dragging => setIsDragging(dragging)}
                onFocusMarkerLocationChange={handleOnFocusMarkerLocationChange}
                onMarkerLocationClicked={handleOnMarkerLocationClicked}
                onPreviousMarkerLocationClicked={handleOnPreviousMarkerLocationClicked}
                previousVisitsMarkers={previousVisitsMarkers}
                showAccuracyArea={!skipLocationChecks}
                showCurrentPosition={true}
                showEditMarkerLocationMessage={showEditMarkerLocationMessage}
                showSsurgo={selectedLayers.includes(MAP_LAYERS.SURGO)}
                showTreatmentZones={selectedLayers.includes(MAP_LAYERS.TREATMENT_ZONES)}
                showTrialArea={selectedLayers.includes(MAP_LAYERS.TRIAL_AREA)}
                soilGeojson={soilGeojson}
                trialAreaGeoJson={trialAreaGeoJson}
                zoneGeojson={zoneGeojson}
              />
            </LoadingOverlay>

            <div css={styles.mapFooter} style={{ justifyContent: skipLocationChecks ? 'end' : 'space-between' }}>
              {!skipLocationChecks && (
                <div css={styles.accuracy}>
                  <Typography fontSize={14}>
                    {t('general.accuracy')}: {accuracy ? Math.round(accuracy) + ' mts' : '-'}
                  </Typography>
                  {accuracyIcon}
                </div>
              )}
              <Button css={styles.mapOptionBtn} variant="text" startIcon={<TuneIcon />} onClick={() => displayFieldMapOptionsModal(treatmentZoneLegends, true)}>
                {t('field-visit-wizard.map-options-button')}
              </Button>
            </div>
          </div>

          <div
            // @ts-ignore
            ref={characteristicSectionRef}
            css={styles.scrollableArea(theme)}
          >
            <CharacteristicSections
              hidden={step > 0}
              fieldName={field?.name || ''}
              data={pages}
              isLoading={getFieldTablesState.isLoading || getFieldVisitsStepsState.isLoading}
              onSelectionChange={selection => setChecked(selection)}
            />

            {/* characteristic sections */}
            {pages
              .filter(({ pageId }) => checked.includes(pageId))
              .map(({ pageId, label, products, questions }, index) => (
                <div key={`questions-${pageId}`} css={styles.characteristicContainer} style={{ display: step === index + 1 ? 'initial' : 'none' }}>
                  <Typography component={'span'} color="text.primary" fontSize={22}>
                    <div css={styles.sectionTitle}>
                      <span>{label}</span>
                      <span
                        css={styles.productBullet(theme)}
                        style={{ backgroundColor: products.length > 0 ? trialProducts.find(tp => tp.id === products.at(0))?.color : 'initial' }}
                      />
                    </div>
                  </Typography>

                  <Characteristic
                    questions={questions}
                    onAddLocation={handleOnAddLocation}
                    onAnswersChange={(characterizationId, value) => assignValueToQuestion(pageId, characterizationId, value)}
                    onQuestionExpanded={handleOnQuestionExpanded}
                    onReCenterLocationEnabled={handleOnReCenterLocationEnabled}
                  />
                </div>
              ))}
          </div>

          {/* Actions Buttons */}
          <div css={styles.actionsContainer(theme)}>
            <Button variant="text" onClick={onCancelWizard} sx={{ textTransform: 'uppercase' }}>
              {t('general.cancel')}
            </Button>
            <ButtonGroup variant="contained">
              <Button
                disabled={step === 0}
                onClick={() => {
                  characteristicSectionRef.current?.scrollTo(0, 0);
                  setStep(step - 1);
                }}
              >
                {t('general.previous')}
              </Button>
              {step === checked.length && step > 0 ? (
                <Button css={styles.actionsBtn} onClick={preComplete} disabled={!hasAnsweredAtleastOneQuestion}>
                  {t('general.complete')}
                </Button>
              ) : (
                <Button
                  css={styles.actionsBtn}
                  disabled={getFieldTablesState.isLoading || getFieldVisitsStepsState.isLoading || checked.length === 0}
                  onClick={() => {
                    saveVisit(false); // save as we go, especially if we are still online
                    characteristicSectionRef.current?.scrollTo(0, 0);
                    setStep(step + 1);
                  }}
                >
                  {t('general.next')}
                </Button>
              )}
            </ButtonGroup>
          </div>

          {/* Error handeling */}
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showError} onClose={() => setShowError(false)}>
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={async () => {
                    await clearFieldVisitProgressWithDexie();
                    closeWizard();
                  }}
                  sx={{ textTransform: 'uppercase' }}
                >
                  {t('field-visit-wizard.error.try-latter-button')}
                </Button>
              }
            >
              <AlertTitle>{t('field-visit-wizard.error.title')}</AlertTitle>
              {t('field-visit-wizard.error.text')}
            </Alert>
          </Snackbar>
        </Paper>
      </LoadingOverlay>
    </>
  );
};

export default FieldVisitWizard;
