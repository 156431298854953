import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useGetAllFieldData } from '@hooks/useGetAllFieldData';
import { getAllFieldVisitsWithDexie, getFieldByProtocolWithDexie, updateFieldWithDexie } from '@services/local';
import { setSyncEnabled } from '@state-mgmt/slices/appSlice';
import { useAsyncCallback } from '@utils/useAsyncCallback';
import { submitLocalFieldVisit } from '@utils/submitLocalFieldVisit';

export const useSubmitsFieldDataAsync = () => {
  const [, getAllFieldData] = useGetAllFieldData();
  const dispatch = useDispatch();

  return useAsyncCallback(
    useCallback(async (localVisitId: number, removeFromLocal: boolean) => {
      const result = await submitLocalFieldVisit(localVisitId, removeFromLocal);
      if (!result) return;

      const { success, visit } = result;
      if (success && visit) {
        const localField = await getFieldByProtocolWithDexie(visit.fieldId, visit.protocolId);
        if (localField) {
          // @ts-ignore
          const { visits } = await getAllFieldData(localField.trialId, localField.protocolId, localField.fieldId, true, true);
          await updateFieldWithDexie(localField.id, { visits });
        }

        const localVisits = await getAllFieldVisitsWithDexie();
        dispatch(setSyncEnabled(localVisits.length ? true : false));
      } else {
        throw new Error('Something went wrong');
      }
    }, [])
  );
};
