import { LocalFieldVisit } from '@mytypes/local';
import { updateFieldVisitWithDexie } from '@services/local';
import { getFieldVisitSteps } from './getFieldVisitSteps';

export const restoreSavedValuesInLocalVisit = async (localFieldVisit: LocalFieldVisit) => {
  const { trialId, protocolId, fieldId, stepId } = localFieldVisit;
  const fieldVisitSteps = await getFieldVisitSteps(trialId, protocolId, fieldId, [stepId]);
  const fieldVisitStep = fieldVisitSteps?.find(fvs => fvs.stepId === stepId);
  if (fieldVisitStep && fieldVisitStep.savedValues) {
    localFieldVisit.savedValues = fieldVisitStep.savedValues;
    updateFieldVisitWithDexie(localFieldVisit.id, localFieldVisit);
  }
};
