/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Divider, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';

import { ViewHeader } from '@components/ViewHeader';
import { useAppTheme } from '@hooks/useAppTheme';
import { useStopSyncingFieldVisitsModal } from '@hooks/modals/useStopSyncingFieldVisitsModal';
import { useGetLocalFieldVisits } from '@hooks/useGetLocalFieldVisits';
import { useGetAllFieldData } from '@hooks/useGetAllFieldData';
import { SYNC_PROGRESS_STATE, SyncData, syncProgress } from '@mytypes/syncFieldVisits';
import { getFieldByProtocolWithDexie, updateFieldWithDexie } from '@services/local';
import { setShowAppNavigator, setSyncEnabled } from '@state-mgmt/slices/appSlice';
import { submitLocalFieldVisit } from '@utils/submitLocalFieldVisit';
import { prepareLocalFieldVisitsToSync } from '@utils/prepareLocalFieldVisitsToSync';
import { styles } from './styles';

const SyncFieldVisits = () => {
  const { t } = useTranslation();

  const theme = useAppTheme({});
  const [getLocalFieldVisitsState, getLocalFieldVisits] = useGetLocalFieldVisits();
  const [, getAllFieldData] = useGetAllFieldData();

  const displayModal = useStopSyncingFieldVisitsModal();

  const [data, setdata] = useState<SyncData[]>([]);
  const [progress, setProgress] = useState<syncProgress[]>([]);
  const [message, setMessage] = useState('');
  const [isSyncing, setIsSyning] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsSyning(true);
    dispatch(setShowAppNavigator(false));
    setMessage(t('sync.getting-data-message'));
    getLocalFieldVisits();
  }, []);

  useEffect(() => {
    if (getLocalFieldVisitsState.isSuccess && getLocalFieldVisitsState.data) {
      const result = prepareLocalFieldVisitsToSync(getLocalFieldVisitsState.data);
      setdata(result.data);
      setProgress(result.progress);
    }
  }, [getLocalFieldVisitsState.isSuccess]);

  const markProgress = async (localId: number, state: SYNC_PROGRESS_STATE) =>
    setProgress(prevState => prevState.map(ps => (ps.localId === localId ? { localId, state } : ps)));

  const checkIfSyncIsStoped = () => {
    // @ts-ignore
    if (window.syncStoped) {
      throw new Error('The field visit Sync process was stopped.');
    }
  };

  const initSync = async (data: SyncData[]) => {
    let fieldVisitNotSynced = 0;
    let fieldsVisiCount = 0;
    // @ts-ignore
    window.syncStoped = false;
    for (let i = 0; i < data.length; i++) {
      checkIfSyncIsStoped();
      const row1 = data[i];
      for (let j = 0; j < row1.trials.length; j++) {
        checkIfSyncIsStoped();
        const row2 = row1.trials[j];
        for (let k = 0; k < row2.fields.length; k++) {
          checkIfSyncIsStoped();
          const row3 = row2.fields[k];
          setMessage(t('sync.syncing-field-message', { id: row3.id, fieldName: row3.fieldName }));

          fieldsVisiCount++;
          // update progress
          markProgress(row3.id, SYNC_PROGRESS_STATE.SYNCING);

          // sync the field visit
          const { success } = await submitLocalFieldVisit(row3.id, true);

          // update progress
          if (success) {
            const localField = await getFieldByProtocolWithDexie(row3.fieldId, row3.protocolId);
            if (localField) {
              // @ts-ignore
              const { visits } = await getAllFieldData(localField.trialId, localField.protocolId, localField.fieldId, true, true);
              await updateFieldWithDexie(localField.id, { visits });
            }
            markProgress(row3.id, SYNC_PROGRESS_STATE.SYNCED);
          } else {
            fieldVisitNotSynced++;
            markProgress(row3.id, SYNC_PROGRESS_STATE.ERROR);
          }
        }
      }
    }

    if (fieldVisitNotSynced > 0) {
      setMessage(`${fieldVisitNotSynced === fieldsVisiCount ? t('general.all') : fieldVisitNotSynced + ' ' + t('sync.count-sync-message')}`);
    } else {
      setMessage(t('sync.success-message'));
    }
    setIsSyning(false);
  };

  useEffect(() => {
    if (data.length) {
      initSync(data);
    }
  }, [data]);

  const getProgressIcon = (localId: number) => {
    const fieldVisitprogress = progress.find(item => item.localId === localId);
    if (fieldVisitprogress && fieldVisitprogress.state === SYNC_PROGRESS_STATE.ON_HOLD) {
      return <HourglassBottomIcon />;
    }
    if (fieldVisitprogress && fieldVisitprogress.state === SYNC_PROGRESS_STATE.SYNCING) {
      return <CircularProgress size={20} />;
    }
    if (fieldVisitprogress && fieldVisitprogress.state === SYNC_PROGRESS_STATE.SYNCED) {
      return <DoneIcon color="success" />;
    }
    if (fieldVisitprogress && fieldVisitprogress.state === SYNC_PROGRESS_STATE.ERROR) {
      return <ErrorIcon color="error" />;
    }
  };

  return (
    <>
      <Paper elevation={2} css={styles.container(theme)}>
        <ViewHeader title={t('sync.title')} hideGoBackBtn={true} />
        {data.map(({ clientId, clientName, trials }) => (
          <div key={clientId}>
            <Typography variant="h1" component="h1" fontSize={18} color="text.primary">
              {clientName}
            </Typography>

            <Divider css={styles.clientDivider} />

            {trials.map(trial => (
              <div key={trial.trialId} css={styles.trialContainer(theme)}>
                <Typography variant="h2" component="h2" fontSize={16} color="text.primary">
                  {trial.trialName}
                </Typography>
                <List>
                  {trial.fields?.map(fieldVisit => (
                    <ListItem disablePadding key={`${fieldVisit.fieldId}_${fieldVisit.stepId}`}>
                      <ListItemIcon css={styles.listItemIcon}>{getProgressIcon(fieldVisit.id)}</ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="h3" component="h3" fontSize={14} color="text.primary" css={styles.fieldVisitTitle(theme)}>
                            #{fieldVisit.id} - {fieldVisit.fieldName}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography variant="subtitle2" component="div" fontSize={12} color="text.secondary">
                              {t('general.protocol')}: {fieldVisit.protocolName}
                            </Typography>
                            <Typography variant="subtitle2" component="div" fontSize={12} color="text.secondary">
                              {t('general.created-at')}: {moment(fieldVisit.createdAt).format('MM/DD/YYYY hh:mm a')}
                            </Typography>
                          </>
                        }
                      />
                      <Divider />
                    </ListItem>
                  ))}
                </List>
              </div>
            ))}
          </div>
        ))}
      </Paper>

      <Paper elevation={0} css={styles.footer(theme)}>
        {isSyncing && <LinearProgress />}
        <Typography css={styles.footerMessage(theme)} fontSize={14} color="text.primary">
          {message}
        </Typography>
        {isSyncing && (
          <Button variant="text" onClick={() => displayModal()}>
            {t('sync.stop-syncing-button')}
          </Button>
        )}

        {!isSyncing && (
          <Button
            variant="contained"
            onClick={() => {
              dispatch(setShowAppNavigator(true));
              dispatch(setSyncEnabled(false));
              navigate('/splash?redirectTo=/');
            }}
          >
            {t('sync.return-button')}
          </Button>
        )}
      </Paper>
    </>
  );
};

export default SyncFieldVisits;
