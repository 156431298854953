import { getFieldVisitValuesApi } from '@services/protocol';
import { getFieldVisitsByFieldIdWithDexie } from '@services/local';
import { FieldVisitStep, QUESTION_TYPE } from '@mytypes/protocol';
import { getProtocolStatus } from './getProtocolStatus';
import { getProtocolStatusField } from './getProtocolStatusField';
import { CharacteristicsByPage, getStepCharacteristics } from './getStepCharacteristics';
import { parseCharacterizationValueReceived } from './parseCharacterizationValueReceived';
import { getLocationValueFromWktPoint } from './getLocationValueFromWktPoint';
import { getStepSavedValues } from './getStepSavedValues';

export const getFieldVisitSteps = async (
  trialId: string,
  protocolId: string,
  fieldId: string,
  includeCharacteristics?: string[],
  includeAllCharacteristics: boolean = false
): Promise<FieldVisitStep[]> => {
  try {
    const [protocolStatusField, protocolStatus, stepValues, localVisits] = await Promise.all([
      getProtocolStatusField(trialId, protocolId, fieldId).catch(err => {
        console.error('An error occurred getProtocolStatusField', err);
        throw new Error('Could not getProtocolStatusField');
      }),
      getProtocolStatus(trialId, protocolId).catch(err => {
        console.error('An error occurred getProtocolStatus', err);
        throw new Error('Could not getProtocolStatus');
      }),
      includeCharacteristics?.length || includeAllCharacteristics
        ? getFieldVisitValuesApi({ trialId, protocolId, fieldId }).catch(err => {
            console.error('An error occurred getFieldVisitValuesApi', err);
            throw new Error('Could not getFieldVisitValuesApi');
          })
        : null,
      getFieldVisitsByFieldIdWithDexie(fieldId).catch(err => {
        console.error('An error occurred getFieldVisitsByFieldIdWithDexie', err);
        throw new Error('Could not getFieldVisitsByFieldIdWithDexie');
      })
    ]);

    let stepIdsForCharacteristics = includeAllCharacteristics ? protocolStatus.map(step => step.step_id) : includeCharacteristics;
    let stepCharacteristics: { [stepId: string]: CharacteristicsByPage | undefined } = {};
    if (stepIdsForCharacteristics?.length) {
      await Promise.all(
        stepIdsForCharacteristics.map(stepId => {
          return getStepCharacteristics(trialId, protocolId, stepId).then(response => {
            stepCharacteristics[stepId] = response;
          });
        })
      );
    }

    const result: FieldVisitStep[] = [];
    for (const step of protocolStatus) {
      const stepStatus = protocolStatusField.find((values: any) => values.step_id === step.step_id);

      const localId: number | null = localVisits.find(localVisit => localVisit.fieldId === fieldId && localVisit.stepId === step.step_id)?.id || null;

      let pages: {
        order: number;
        pageId: string;
        label: string;
        products: string[];
        questions: {
          latitude?: number | undefined;
          longitude?: number | undefined;
          characterization_id: string;
          label: string;
          options: string[];
          order: number;
          required: boolean;
          value: any;
          valueId: string[] | undefined;
          valueType: QUESTION_TYPE;
        }[];
      }[] = [];

      const characteristicsByPage = stepCharacteristics[step.step_id];

      if (characteristicsByPage && 'no-page' in characteristicsByPage) {
        const questionsParsed = characteristicsByPage['no-page'].map(({ characterization_id, label, options, order, required, value_type }) => {
          const characteritValues = stepValues?.values.filter(characteritValue => characteritValue.characterization_id === characterization_id);

          return {
            characterization_id,
            label,
            options,
            order,
            pageId: 'no-page',
            required,
            value: parseCharacterizationValueReceived(characteritValues, value_type as QUESTION_TYPE),
            valueId: characteritValues?.map(v => v.id),
            valueType: value_type,
            ...(value_type !== QUESTION_TYPE.UPLOAD &&
              characteritValues?.length &&
              characteritValues[0].wkt_point &&
              getLocationValueFromWktPoint(characteritValues[0].wkt_point))
          };
        });

        pages.push({
          order: 0,
          pageId: 'no-page',
          label: 'Standalone Questions',
          products: [],
          questions: questionsParsed
        });
      }

      step.pages.map(({ page_id, number, label, product_ids }) => {
        const pageCharacteritctis = characteristicsByPage?.[page_id];

        const pageCharacteritctisWithValues = pageCharacteritctis?.map(({ characterization_id, label, required, order, value_type, options }) => {
          const characteritValue = stepValues?.values.filter((v: any) => v.characterization_id === characterization_id);

          return {
            characterization_id,
            label,
            options: options,
            order,
            required,
            value: parseCharacterizationValueReceived(characteritValue, value_type as QUESTION_TYPE),
            valueId: characteritValue?.map(v => v.id),
            valueType: value_type as QUESTION_TYPE,
            ...(value_type !== QUESTION_TYPE.UPLOAD &&
              characteritValue?.length &&
              characteritValue[0].wkt_point &&
              getLocationValueFromWktPoint(characteritValue[0].wkt_point))
          };
        });

        if (pageCharacteritctisWithValues) {
          pages = [
            ...pages,
            {
              order: number + 1,
              pageId: page_id,
              label: label,
              products: product_ids || [],
              questions: pageCharacteritctisWithValues || []
            }
          ];
        }
      });

      result.push({
        localId,
        stepId: step.step_id,
        label: step.label,
        completedBy:
          stepStatus?.completed_by_first || stepStatus?.completed_by_last
            ? `${stepStatus?.completed_by_first || ''} ${stepStatus?.completed_by_last || ''}`
            : 'N/A',
        completedAt: `${stepStatus?.completed_date}`,
        mainActionCompleted: stepStatus?.main_action_completed || '',

        startDate: step.start_date || '',
        endDate: step.end_date,

        pages
      });
    }

    // get saved values for all steps
    for (const step of result) {
      step['savedValues'] = getStepSavedValues(step);
    }

    return result;
  } catch (error) {
    console.error('an error occurred getFieldVisitSteps', error);
    throw new Error('Could not getFieldVisitSteps');
  }
};
