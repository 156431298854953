import { useCallback } from 'react';
import { useAsyncCallback } from '@react-hooks-library/core';
import { getFieldVisitSteps } from '@utils/getFieldVisitSteps';

export const useGetFieldVisitsSteps = () => {
  return useAsyncCallback(
    useCallback(async (trialId: string, protocolId: string, fieldId: string, includeCharacteristics?: string[], includeAllCharacteristics: boolean = false) => {
      try {
        return getFieldVisitSteps(trialId, protocolId, fieldId, includeCharacteristics, includeAllCharacteristics);
      } catch (error) {
        console.error('an error occurred fetching field visit steps', error);
        throw new Error('Could not getFieldVisitSteps');
      }
    }, [])
  );
};
