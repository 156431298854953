import { Characteristic } from '@mytypes/protocol';
import { getStepCharacterizationsApi } from '@services/protocol';

export interface CharacteristicsByPage {
  [key: string]: Characteristic[];
}

const groupCharacteristicsByPage = (characteristics: Characteristic[]): CharacteristicsByPage => {
  return characteristics.reduce((result: CharacteristicsByPage, characteristic) => {
    const pageId = characteristic.page_id || 'no-page';
    if (!result[pageId]) {
      result[pageId] = [];
    }
    result[pageId].push(characteristic);
    return result;
  }, {});
};

export const getStepCharacteristics = async (trialId: string, protocolId: string, stepId: string): Promise<CharacteristicsByPage> => {
  try {
    const fieldCharacterizations = (await getStepCharacterizationsApi({ trialId, protocolId, stepId }))?.field_characterizations?.standard;

    if (!fieldCharacterizations) return {};

    const visibleCharacteristics = fieldCharacterizations.filter(fc => !fc.hide);
    return groupCharacteristicsByPage(visibleCharacteristics);
  } catch (error) {
    console.error('An error occurred getStepCharacteristics', error);
    throw new Error('Could not getStepCharacteristics');
  }
};
